'use client'

import { LinkButtonProps } from '@/components/common/button/LinkButton'
import React, { ReactElement } from 'react'
import { LinkTarget } from '@/types/Page'
import classNames from 'classnames'
import {
  BUTTON_STYLES_BG_PRIMARY,
  BUTTON_STYLES_BG_PRIMARY_LIGHTENED,
  LARGE_BUTTON_STYLES,
  LINK_STRETCH_STYLES,
  STANDARD_BUTTON_STYLES,
} from '@/components/common/button/styles'
import { ICONS } from '@/components/common/button/icons'
import { twMerge } from 'tailwind-merge'

export function FakeLink(props: LinkButtonProps & { showModal: () => void }): ReactElement {
  return (
    <form action={props.href} method="POST" target={props.linkTarget}>
      <button
        onClick={props.linkTarget === LinkTarget.MODAL ? props.showModal : undefined}
        className={twMerge(
          classNames(
            props.large ? LARGE_BUTTON_STYLES : STANDARD_BUTTON_STYLES,
            props.className,
            props.bgLightened ? BUTTON_STYLES_BG_PRIMARY_LIGHTENED : BUTTON_STYLES_BG_PRIMARY,
            props.icon ? ICONS[props.icon] : undefined,
            {
              [LINK_STRETCH_STYLES]: props.stretch,
            },
          ),
          props.className,
        )}
      >
        {props.children}
      </button>
    </form>
  )
}
