'use client'

import React, { MouseEventHandler, ReactElement } from 'react'
import classNames from 'classnames'
import ImageCaption from '@/components/elements/images_with_text_element/ImageCaption'

interface HeaderLinkProps {
  href?: string
  onClick?: MouseEventHandler<HTMLAnchorElement>
  additionalClasses?: string
  imageCaption: string
  target?: string
  rel?: string
  minPrice: string | undefined
}

export default function HeaderLink(props: HeaderLinkProps): ReactElement {
  return (
    <a
      href={props.href}
      className={classNames(
        'items-left absolute bottom-0 left-0 right-0 top-0 z-20  flex cursor-pointer flex-col justify-end p-4 ' +
          'text-white transition after:pointer-events-auto after:absolute after:inset-0 after:z-20 after:cursor-pointer ' +
          "after:bg-[rgba(0,0,0,0)] after:content-[''] hover:bg-stone-700/20",
        props.additionalClasses,
      )}
      onClick={props.onClick}
      target={props.target}
      rel={props.rel}
    >
      <ImageCaption minPrice={props.minPrice} imageCaption={props.imageCaption} />
    </a>
  )
}
