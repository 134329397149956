'use client'

import { LinkButtonProps } from '@/components/common/button/LinkButton'
import React, { ReactElement } from 'react'
import { LinkTarget } from '@/types/Page'
import classNames from 'classnames'
import {
  LINK_STRETCH_STYLES,
  LINK_STYLES,
  BUTTON_STYLES_BG_PRIMARY,
  BUTTON_STYLES_BG_PRIMARY_LIGHTENED,
  STANDARD_BUTTON_STYLES,
} from '@/components/common/button/styles'
import { ICONS } from '@/components/common/button/icons'
import { twMerge } from 'tailwind-merge'

export function Link(props: LinkButtonProps & { showModal?: () => void }): ReactElement {
  return (
    <>
      <a
        href={props.href || '/'}
        target={props.linkTarget}
        onClick={
          props.linkTarget === LinkTarget.MODAL
            ? (e) => {
                e.preventDefault()
                props.showModal!()
              }
            : undefined
        }
        className={twMerge(
          classNames(
            props.icon ? ICONS[props.icon] : null,
            {
              [LINK_STRETCH_STYLES]: props.stretch,
            },
            {
              [BUTTON_STYLES_BG_PRIMARY_LIGHTENED]: props.bgLightened && !props.styleAsLink,
              [BUTTON_STYLES_BG_PRIMARY]: !props.bgLightened && !props.styleAsLink,
              [STANDARD_BUTTON_STYLES]: !props.styleAsLink,
              [LINK_STYLES]: props.styleAsLink,
            },
          ),
          props.className,
        )}
      >
        {props.children}
      </a>
    </>
  )
}
