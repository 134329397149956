import React, { ReactElement } from 'react'
import classNames from 'classnames'
import { stateIcon } from '@/components/elements/FaqElement'

interface ButtonStateProps {
  showMore: boolean
  additionalClassNames?: string
}

export default function ButtonState(props: ButtonStateProps): ReactElement {
  return (
    <div
      className={classNames(
        'w-15 ml-2 mt-0.5 flex h-6 origin-center items-center justify-center duration-200 ease-out',
        props.additionalClassNames,
        { 'rotate-0': !props.showMore, 'rotate-180': props.showMore },
      )}
    >
      {stateIcon}
    </div>
  )
}
