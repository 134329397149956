'use client'
import React, { ReactElement } from 'react'
import { NumberOfPreloadedImages } from '@/components/ui/search/image-slider/constants'
import { PictureSizeDefinition } from '@/components/common/picture/Picture'
import { Splide, SplideSlide } from '@splidejs/react-splide'

export interface ImageSliderProps {
  onClick?: (e: React.MouseEvent<HTMLLIElement>) => void
  numberOfImagesToPreload: NumberOfPreloadedImages
  images: string[]
  href: string
  alt: string
  openInNewWindow?: boolean
  pictureSizeDefinition: PictureSizeDefinition
  height?: string
}

interface ImageSliderClientComponentProps extends ImageSliderProps {
  pictures: ReactElement[]
}

export default function ImageSliderClientComponent(
  props: ImageSliderClientComponentProps,
): ReactElement {
  function images(): string[] {
    return props.images
  }

  return (
    <div className="image-slider group relative h-full w-full cursor-pointer overflow-hidden">
      <Splide
        options={{
          perPage: 1,
          height: props.height || '200px',
          pagination: true,
          classes: {
            prev: 'splide__arrow--prev bg-white shadow-md',
            next: 'splide__arrow--next bg-white shadow-md',
          },
          mediaQuery: 'min',
          arrowPath:
            'M31.2538 19.9967L30.1252 21.1252L17.3857 33.8714L16.2571 35L14.0066 32.7429L15.1352 31.6143L26.7528 19.9967L15.1286 8.37907L14 7.2505L16.2571 5L17.3857 6.12857L30.1319 18.8681L31.2605 19.9967H31.2538Z',
        }}
      >
        {images().map((image, index) => (
          <SplideSlide key={image} className="h-full cursor-pointer pb-2" onClick={props.onClick}>
            {props.pictures[index]}
          </SplideSlide>
        ))}
      </Splide>
    </div>
  )
}
