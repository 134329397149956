import { ReactElement, ReactNode } from 'react'
import classNames from 'classnames'
import { twMerge } from 'tailwind-merge'

interface WrapperProperties {
  children: ReactNode
  classNamesInnerContainer?: string
  className?: string
  padding?: string
  style?: Record<string, string>
}

export default function WrapperElement(props: WrapperProperties): ReactElement {
  return (
    <div className={twMerge('mb-8', props.className)}>
      <div
        className={twMerge(
          classNames('mx-auto max-w-screen-xl', props.padding ?? 'px-5'),
          props.classNamesInnerContainer,
        )}
        style={props.style}
      >
        {props.children}
      </div>
    </div>
  )
}
