'use client'

import React, {
  AnchorHTMLAttributes,
  DetailedHTMLProps,
  ReactElement,
  ReactNode,
  useState,
} from 'react'
import { ButtonIcon } from '@/components/common/button/constants'
import { LinkTarget } from '@/types/Page'
import { LinkModal } from '@/components/common/Modal'
import { FakeLink } from '@/components/common/button/FakeLink'
import { Link } from '@/components/common/button/Link'

export interface LinkButtonProps
  extends DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
  children: ReactNode
  stretch?: boolean
  icon?: ButtonIcon
  className?: string
  linkTarget: LinkTarget
  linkPostRequest?: boolean
  large?: boolean
  styleAsLink?: boolean
  bgLightened?: boolean
}

export function LinkButton(props: LinkButtonProps): ReactElement {
  const [showModal, setShowModal] = useState(false)

  if (props.linkPostRequest) {
    return (
      <>
        <FakeLink
          {...props}
          showModal={() => {
            setShowModal(true)
          }}
          className={props.className}
        />
        {showModal && <LinkModal setShowModal={setShowModal} href={props.href || ''} />}
      </>
    )
  } else {
    return (
      <>
        <Link
          {...props}
          showModal={() => {
            setShowModal(true)
          }}
          className={props.className}
        />
        {showModal && <LinkModal setShowModal={setShowModal} href={props.href || ''} />}
      </>
    )
  }
}
