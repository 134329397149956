'use client'

import { HotelsProperties } from '@/types/Page'
import { useContext, useEffect } from 'react'
import { ConfigContext } from '@/components/common/ConfigContext'
import { trackUserAction } from '@/lib/frontent-api'
import { TrackingDataEventTypes } from '@/types/Misc'

export function TrackHotelImpressions(props: HotelsProperties | Record<string, never>) {
  const domain = useContext(ConfigContext)!.domain

  useEffect(() => {
    if (props.items) {
      trackUserAction(domain, {
        type: TrackingDataEventTypes.HOTEL_IMPRESSION_LANDING_PAGE,
        hotelCodes: props.items.map((item) => item.hotelCode),
      })
    }
  }, [])

  return <></>
}
