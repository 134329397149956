import React, { ReactElement, ReactNode } from 'react'
import classNames from 'classnames'

export function ImageTitle(props: {
  imageTitlePriceFrom: ReactNode
  link: string
  title: string
  minPrice: string | undefined
  additionalClassNames?: string
}): ReactElement {
  return (
    <a
      href={props.link}
      rel="noopener noreferrer"
      className={classNames(
        'shadow-overlay items-left absolute bottom-0 left-0 right-0 top-0 z-10 flex flex-col flex-wrap justify-end p-4 text-white transition hover:bg-stone-700/20',
        props.additionalClassNames,
      )}
    >
      <span className="w-full hyphens-auto break-words text-2xl font-semibold drop-shadow-xl">
        {props.title}
      </span>
      {props.minPrice && (
        <span className="drop-shadow-md">
          {props.imageTitlePriceFrom} {props.minPrice}
        </span>
      )}
    </a>
  )
}
