'use client'

import { ReactElement, useEffect } from 'react'
import { trackUserAction } from '@/lib/frontent-api'
import { Domain } from '../../domain-config/domain-config'
import { TrackingDataEventTypes } from '@/types/Misc'

export function PageViewTracking(props: { pageId: string; domain: Domain }): ReactElement {
  useEffect(() => {
    if (props.pageId) {
      trackUserAction(props.domain, {
        pageId: props.pageId,
        type: TrackingDataEventTypes.LANDING_PAGE_VIEW,
      })
    }
  }, [props.pageId])

  return <></>
}
