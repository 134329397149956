'use client'

import { ImagesWithTextItem } from '@/types/Page'
import { LinkModal } from '@/components/common/Modal'
import React, { ReactElement, useState } from 'react'
import HeaderLink from '@/components/elements/images_with_text_element/HeaderLink'

export default function ModalLink(props: { gridItem: ImagesWithTextItem }): ReactElement {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <HeaderLink
        onClick={(e) => {
          setShowModal(true)
          e.preventDefault()
        }}
        imageCaption={props.gridItem.imageCaption}
        minPrice={props.gridItem.minPrice}
      />
      {showModal && <LinkModal setShowModal={setShowModal} href={props.gridItem.linkUrl} />}
    </>
  )
}
