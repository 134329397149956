'use client'

import React, { ReactElement, useState } from 'react'
import { FaqProperties } from '@/types/Page'
import classNames from 'classnames'
import Article from '@/components/common/article'
import WrapperElement from '@/components/elements/WrapperElement'
import ButtonState from '@/components/elements/ButtonState'

export const stateIcon = (
  <svg
    className="stroke-stone-400"
    width="14"
    height="7"
    viewBox="0 0 14 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6618 1L7.35006 5.89955C7.31559 5.9314 7.27466 5.95666 7.2296 5.97389C7.18453 5.99113 7.13623 6 7.08745 6C7.03867 6 6.99036 5.99113 6.9453 5.97389C6.90024 5.95666 6.8593 5.9314 6.82483 5.89955L1.51306 1"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default function FaqElement(props: FaqProperties): ReactElement {
  const [openStates, setOpenStates] = useState(props.faqItems.map(() => false))

  function toggleOpenState(i: number): void {
    const newState = [...openStates]
    newState[i] = !newState[i]

    setOpenStates(newState)
  }

  function stripHtmlTags(str: string): string {
    return str.replace(/<\/?[^>]+(>|$)/g, '')
  }

  function jsonLdInfo(): Record<string, unknown> {
    return {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: props.faqItems.map((f) => ({
        '@type': 'Question',
        name: f.question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: stripHtmlTags(f.answer),
        },
      })),
    }
  }

  return (
    <WrapperElement classNamesInnerContainer="">
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLdInfo()) }}
      ></script>
      <div className="divide-y rounded-md bg-white shadow-md">
        {props.faqItems.map((f, index) => (
          <React.Fragment key={f.question}>
            <div>
              <div
                onClick={() => toggleOpenState(index)}
                className={classNames(
                  'position-relative grid cursor-pointer grid-cols-[minmax(0px,_1fr)_26px] gap-x-2 p-3.5 text-md font-medium text-stone-800',
                  { 'shadow-[0px_1px_0px_rgba(0,0,0,0)]': openStates[index] },
                )}
              >
                {f.question}
                <ButtonState showMore={openStates[index]} />
              </div>
              <div
                className={classNames(
                  'h-100 font-size-lg overflow-hidden px-3.5 duration-200 ease-out',
                  { 'h-0 pb-0 pt-0': !openStates[index] },
                )}
              >
                <Article additionalClassName="pb-4" text={f.answer} />
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </WrapperElement>
  )
}
