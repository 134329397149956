'use client'

import React, { ReactElement, ReactNode, useEffect, useState } from 'react'
import CloseButton from '@/components/elements/CloseButton'
import { lockScrollableBody, onMobileOrTablet, unlockScrollableBody } from '@/lib/utils/utils'
import classNames from 'classnames'
import LoadingIndicator from '@/components/elements/LoadingIndicator'
import { ModalNew } from '@/components/common/ModalNew'

interface ModalProps {
  largeModal?: boolean
  noSroll?: boolean
  visible: boolean
  close: () => void
  heading?: ReactNode
  footer?: ReactNode
  children: ReactNode
  classNameAdditionalBody?: string
  additionalClassName?: string
  mobileFullscreen: boolean
  mobileHeader?: boolean
  hideHeader?: boolean
  background?: boolean
}

function Header(props: {
  closed: () => void
  heading: ReactNode
  mobileHeader: boolean
}): ReactElement {
  return (
    <div
      className={classNames('rounded-t-md border-b-2 border-gray-100 bg-white', {
        'hidden lg:flex': !props.mobileHeader,
        flex: props.mobileHeader,
      })}
    >
      <div className="flex-grow p-4 pl-[40px] text-center text-2xl">{props.heading}</div>
      <CloseButton onClick={props.closed} />
    </div>
  )
}

function Body(props: { children: ReactNode; scrollable: boolean }): ReactElement {
  return (
    <div
      className={classNames(
        'min-h-0 flex-grow overflow-x-hidden rounded-b-md rounded-t-md bg-white lg:rounded-t-none',
        {
          'overflow-y-scroll': props.scrollable,
          'overflow-y-hidden': !props.scrollable,
        },
      )}
    >
      {props.children}
    </div>
  )
}

function Footer(props: { children: ReactNode }): ReactElement {
  return (
    <div className="order-b-2 flex justify-end border-gray-100 p-5 md:p-8">{props.children}</div>
  )
}

export default function Modal(props: ModalProps): ReactElement {
  useEffect(() => {
    if (!onMobileOrTablet()) {
      return
    }

    if (props.visible) {
      lockScrollableBody()
    } else {
      unlockScrollableBody()
    }
  }, [props.visible])

  useEffect(() => {
    return () => unlockScrollableBody()
  }, [])

  function close(): void {
    unlockScrollableBody()
    props.close()
  }

  return (
    <div
      className={classNames(
        'modal fixed inset-0 left-0 top-0 z-50 flex max-h-screen items-center justify-center lg:pb-10 lg:pt-10',
        {
          'm-5 rounded-md md:m-0': !props.mobileFullscreen,
          hidden: !props.visible,
          'bg-black bg-opacity-30': props.background,
        },
        props.additionalClassName,
      )}
      onClick={close}
    >
      <div
        className={classNames(
          'mx-30 overflow-none relative my-10 flex h-full max-h-screen w-full flex-col rounded-md bg-transparent bg-white drop-shadow-[0_2px_4px_rgba(0,0,0,0.2)] md:mx-8 md:h-5/6',
          props.classNameAdditionalBody,
          {
            'xl:max-w[1240px]': props.largeModal,
            'md:max-w-[704px]': !props.largeModal,
            'rounded-md': !props.mobileFullscreen,
          },
        )}
        onClick={(e) => e.stopPropagation()}
      >
        {!props.hideHeader && (
          <Header closed={close} heading={props.heading} mobileHeader={!!props.mobileHeader} />
        )}
        <Body scrollable={!props.noSroll}>{props.children}</Body>
        {props.footer && <Footer>{props.footer}</Footer>}
      </div>
    </div>
  )
}

interface LinkModalProps {
  setShowModal: (showModal: boolean) => void
  href: string
  title?: string
}

export function LinkModal(props: LinkModalProps): ReactElement {
  const [iframeLoaded, setIframeLoaded] = useState(false)

  return (
    <ModalNew close={() => props.setShowModal(false)} title={props.title || ''} isOpen={true}>
      {!iframeLoaded && (
        <div className="flex h-[calc(100vh_-_62px)] w-full items-center justify-center sm:h-[80vh]">
          <LoadingIndicator additionalClassName="m-auto block w-[300px] h-[300px] scale-75" />
        </div>
      )}
      <iframe
        className={classNames('w-full', {
          'h-[calc(100vh_-_62px)] sm:h-[calc(80vh_-_62px)]': iframeLoaded,
          'h-0': !iframeLoaded,
        })}
        src={props.href}
        onLoad={() => setIframeLoaded(true)}
      />
    </ModalNew>
  )
}
