'use client'
import { ReactElement } from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide'

interface TrustLargeSliderElementProps {
  sliderElements: ReactElement[]
}
export default function TrustLargeElementSlider(props: TrustLargeSliderElementProps): ReactElement {
  return (
    <Splide
      options={{
        fixedWidth: '390px',
        height: 'auto',
        pagination: false,
        arrows: false,
        gap: '2rem',
        classes: {
          prev: 'splide__arrow--prev bg-white shadow-md',
          next: 'splide__arrow--next bg-white shadow-md',
        },
        padding: {
          right: '1.25rem',
          left: '1.25rem',
        },
        mediaQuery: 'min',
        breakpoints: {
          1280: {
            padding: {
              right: '0',
              left: '0',
            },
          },
        },
        arrowPath:
          'M31.2538 19.9967L30.1252 21.1252L17.3857 33.8714L16.2571 35L14.0066 32.7429L15.1352 31.6143L26.7528 19.9967L15.1286 8.37907L14 7.2505L16.2571 5L17.3857 6.12857L30.1319 18.8681L31.2605 19.9967H31.2538Z',
      }}
    >
      {props.sliderElements.map((sliderElement, index) => (
        <SplideSlide className="h-full cursor-pointer pb-2" key={index}>
          {sliderElement}
        </SplideSlide>
      ))}
    </Splide>
  )
}
