'use client'

import { ImageTitle } from '@/components/common/image/ImageTitle'
import React, { ReactElement, ReactNode } from 'react'
import { ImageRowProperties } from '@/types/Page'

import { Splide, SplideSlide } from '@splidejs/react-splide'
import { useTranslations } from 'next-intl'

type ImageRowClientComponentProps = ImageRowProperties & { pictures: ReactNode[] }

export default function ImageRowClientComponent(props: ImageRowClientComponentProps): ReactElement {
  const t = useTranslations('Misc')
  function slidesPerView(max: number): number {
    return Math.min(max, props.items.length)
  }

  return (
    <>
      <Splide
        options={{
          fixedWidth: '240px',
          height: '200px',
          pagination: false,
          gap: '1rem',
          classes: {
            prev: 'splide__arrow--prev bg-white shadow-md',
            next: 'splide__arrow--next bg-white shadow-md',
          },
          mediaQuery: 'min',
          breakpoints: {
            640: { slidesPerView: slidesPerView(2.5), spaceBetween: 16 },
            768: { slidesPerView: slidesPerView(3.5), spaceBetween: 24 },
            1024: { slidesPerView: slidesPerView(4.5), spaceBetween: 24 },
          },

          arrowPath:
            'M31.2538 19.9967L30.1252 21.1252L17.3857 33.8714L16.2571 35L14.0066 32.7429L15.1352 31.6143L26.7528 19.9967L15.1286 8.37907L14 7.2505L16.2571 5L17.3857 6.12857L30.1319 18.8681L31.2605 19.9967H31.2538Z',
        }}
      >
        {props.items.map((item, index) => (
          <SplideSlide key={index} className="h-full cursor-pointer pb-2">
            <div>
              <ImageTitle
                link={item.url}
                title={item.text}
                minPrice={item.minPrice}
                additionalClassNames={'before:rounded-b-[5px]'}
                imageTitlePriceFrom={t('imageTitlePriceFrom')}
              />
              {props.pictures[index]}
            </div>
          </SplideSlide>
        ))}
      </Splide>
    </>
  )
}
