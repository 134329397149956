import { ReactElement, RefObject } from 'react'
import classNames from 'classnames'

const POSE_ELEMENT_STYLES = {
  h1: 'md:pose-h1:text-4xl pose-h1:text-stone-900 pose-h1:font-medium pose-h1:text-2xl pose-h1:font-semibold pose-h1-p-sibling:md:mt-8 pose-h1-p-sibling:mt-7 pose-h1:last:mb-2',
  h2: 'md:pose-h2:text-2xl pose-h2:text-stone-900 sm:pose-h2:font-medium pose-h2:text-xl pose-h2:font-semibold md:pose-h2-h1-sibling:mt-1.5 pose-h2-h1-sibling:mt-1.5 pose-h2-p-sibling:mt-7 pose-h2-ol-sibling:mt-3.5 pose-h2:last:mb-2',
  h3: 'md:pose-h3:text-xl pose-h3:text-stone-900 sm:pose-h3:font-medium pose-h3:text-md pose-h3:font-semibold md:pose-h3-h2-sibling:mt-1.5 pose-h3-h2-sibling:mt-1.5 pose-h3-p-sibling:mt-6 pose-h3:last:mb-2',
  h4: 'md:pose-h4:text-lg pose-h4:text-stone-900 sm:pose-h4:font-medium pose-h4:text-sm pose-h4:font-semibold md:pose-h4-h3-sibling:mt-1.5 pose-h4-h3-sibling:mt-0.5 pose-h4-p-sibling:mt-6 pose-h4:last:mb-2',
  h5: 'md:pose-h5:text-md pose-h5:text-stone-900 sm:pose-h5:font-medium pose-h5:text-sm pose-h5:font-semibold md:pose-h5-h4-sibling:mt-1.5 pose-h5-h4-sibling:mt-0.5 pose-h5-p-sibling:mt-6 pose-h5:last:mb-2',
  h6: 'md:pose-h6:text-md pose-h6:text-stone-900 sm:pose-h6:font-medium pose-h6:text-sm pose-h6:font-semibold md:pose-h6-h5-sibling:mt-1.5 pose-h6-h5-sibling:mt-0.5 pose-h6-p-sibling:mt-6 pose-h6:last:mb-2',
  p: 'pose-p:text-stone-800 pose-p:mb-2.5 pose-p:leading-2 pose-p:font-regular pose-p:text-sm pose-p-h1-sibling:md:mt-2 pose-p-h1-sibling:mt-3 pose-p-h2-sibling:mt-2 md:pose-p-h3-sibling:mt-2 pose-p-h3-sibling:mt-2 pose-p-h4-sibling:mt-2 pose-p-p-sibling:mt-2 pose-p-ul-sibling:mt-3 pose-p-ol-sibling:mt-2.5 pose-p:last:mb-2 pose-p:last:mb-2',
  a: 'pose-a:text-primary hover:pose-a:underline pose-a:last:mb-2 pose-a:underline',
  blockquote:
    'pose-blockquote:text-stone-800 pose-blockquote:border-l-4 pose-blockquote:italic pose-blockquote:pl-4 pose-blockquote:mt-10 pose-blockquote:mb-10 md:pose-blockquote:text-2xl pose-blockquote:text-lg pose-blockquote:last:mb-2',
  ul: 'pose-ul:mb-2.5 pose-ul:text-sm pose-ul:m-0 pose-ul:list-disc md:pose-ul-h3-sibling:mt-2.5 pose-ul-h3-sibling:mt-2.5 pose-ul:ml-4 pose-ul:-mr-4 pose-ul:pr-4 pose-ul:last:mb-2',
  ol: 'pose-ol:mb-2.5 pose-ol:text-sm pose-ol:m-0 pose-ol:list-decimal pose-ol:pl-7 pose-ol-h1-sibling:mt-2.5 pose-ol:last:mb-2',
  li: 'pose-li:text-stone-800 pose-li:text-sm pose-li:m-0 pose-li:relative pose-li:left-4 pose-li:pr-4',
  strong: 'pose-strong:text-sm pose-strong:font-semibold pose-strong:last:mb-2',
  em: 'pose-em:text-sm pose-sem:text-regular pose-em:last:mb-2',
  table:
    'pose-table:text-sm pose-table:min-w-full pose-table:w-auto pose-table:pr-5 pose-table:last:mb-2',
  th: 'pose-th:text-stone-900 pose-th:text-stone-900 pose-th:font-medium pose-th:px-2 pose-th:text-sm pose-th:bg-primary-lightened pose-th:text-left pose-th:table-cell pose-th:last:mb-2',
  tbody: 'pose-tbody:shuffled-bg',
  tr: 'pose-tr:whitespace-nowrap',
  td: 'pose-td:text-stone-800 pose-td:px-2 pose-td:py-2 pose-td-div-child:text-red pose-td:table-cell pose-td:whitespace-normal pose-td:align-top',
}

export default function Article(props: {
  text: string
  additionalClassName?: string
  itemProp?: string
  articleReference?: RefObject<HTMLDivElement>
}): ReactElement {
  return (
    <article
      ref={props.articleReference}
      className={classNames(
        'pose min-w-0 text-sm h2-sibling:mb-6',
        Object.values(POSE_ELEMENT_STYLES).join(' '),
        props.additionalClassName,
      )}
      dangerouslySetInnerHTML={{ __html: props.text }}
      itemProp={props.itemProp}
    />
  )
}
