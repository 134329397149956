'use client'

import { ImagesWithTextItem } from '@/types/Page'
import HeaderLink from '@/components/elements/images_with_text_element/HeaderLink'
import React, { ReactElement } from 'react'

export function HiddenLink(props: {
  gridItem: ImagesWithTextItem
  targetUrl: string
}): ReactElement {
  return (
    <HeaderLink
      imageCaption={props.gridItem.imageCaption}
      onClick={() => (window.location.href = props.targetUrl)}
      minPrice={props.gridItem.minPrice}
    />
  )
}
