import React, { ReactElement } from 'react'
import classNames from 'classnames'

export default function ImageCaption(props: {
  minPrice: string | undefined
  imageCaption: string
}): ReactElement {
  return (
    <span className={classNames('z-20 text-2xl font-semibold', { 'mb-6': props.minPrice })}>
      {' '}
      {props.imageCaption}
    </span>
  )
}
