'use client'

import { ReactElement } from 'react'
import Button from '@/components/common/button/Button'
import { LinkTarget } from '@/types/Page'
import { ButtonType } from '@/components/common/button/ButtonType'

export function ContactElementButton(props: {
  buttonlink: string
  buttontext: string | undefined
}): ReactElement {
  return (
    <Button
      buttonType={ButtonType.PRIMARY}
      onClick={() => window.open(props.buttonlink, LinkTarget.BLANK)}
      className="whitespace-nowrap"
    >
      {props.buttontext}
      <div className="ml-4 inline-block h-[11px] w-[7px] icon-button-chevron_right" />
    </Button>
  )
}
